<template>
  <main>
    <error-loading v-if="isLoading" />
    <div v-else>
      <error-data  />
    </div>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorLoading from '@/base/components/Error/Loading'
import ErrorData from '@/base/components/Error/Data'
import viewMixin from '@/base/mixins/view.mixin'
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'Error',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().error
    }
  },
  mixins: [viewMixin],
  components: {
    ErrorData,
    ErrorLoading
  },
  props: {
    errorHash: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.error.loading
    })
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA'
    ]),
    ...mapActions([
      'getErrorTrxData'
    ])
  },
  mounted () {
    if (!this.errorHash) {
      return
    }

    const data = {
      errorHash: this.errorHash
    }
    this.getErrorTrxData(data)
      .catch(error => {
        this.SET_ERROR_DATA({
          errorCode: error.body.errorCode,
          errorUrl: error.body.errorUrl
        })
      })
  }
}
</script>

<template>
  <div class="container">
    <div class="columns">
      <div class="column-full">
        <div class="content loader-wrapper">
          <default-loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLoader from '@/base/components/Loaders/DefaultLoader'
export default {
  name: 'ErrorLoading',
  components: { DefaultLoader }
}
</script>
